
import { defineComponent, computed, onMounted, ref } from "vue";
import type { PropType } from "vue";
import type {
  IAgentDto,
  TerritoryDto,
} from "@/shared/service-proxies/service-proxies";
import { useRoute } from "vue-router";
import useTerritories from "@/composables/useTerritories";
import { TerritoriesFilter } from "@/models";
import "vue-select/dist/vue-select.css";
import { useStore } from "vuex";

export default defineComponent({
  name: "TerritoryForm",
  components: {},
  props: {
    modelValue: {
      type: Object as PropType<IAgentDto>,
      required: true,
    },
    saving: {
      type: Boolean,
    },
  },

  emits: ["update:modelValue", "submit"],

  setup(props, { emit }) {
    const route = useRoute();
    const loaded = ref(false);
    const dataId = computed(() => route.params.id);
    const agent = computed({
      get: () => props.modelValue,
      set: (val) => emit("update:modelValue", val),
    });
    const store = useStore();

    const { getTerritories, territories } = useTerritories();

    const defaultTerritoryFilter = {
      filter: null,
      territoryCodeFilter: null,
      territoryNameFilter: null,
      territoryDescriptionFilter: null,
      sorting: null,
      skipCount: undefined,
      maxResultCount: 1000,
      cancelToken: null,
    } as unknown as TerritoriesFilter;
    const selectTerritories = ref([] as unknown as TerritoryDto[]);

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    onMounted(async () => {
      await getTerritories(defaultTerritoryFilter);

      territories.value.map((territory) => {
        selectTerritories.value.push(territory.territory);
      });

      loaded.value = true;
    });

    return {
      agent,
      route,
      selectTerritories,
      loaded,
      dataId,
      breadcrumbs,
      pageTitle,
    };
  },
});
